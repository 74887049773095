<div class="container-fluid">
  <div class="row block-desktop justify-content-center align-items-center align-self-center">
    <div class="col col-md-12 text-center">
        <h5 class="title-curse affogato-bold" *ngIf="!ally || ally.name !== 'Comex'">Las categorías de nuestras lecciones son</h5>
        <h5 class="title-curse affogato-bold" *ngIf="ally && ally.name === 'Comex'">Y más lecciones basadas en:</h5>
    </div>
    <div class="col col-md-7 col-12 text-center">  
      <div class="row justify-content-center text-center" *ngIf="!ally || ally.name !== 'Comex'">
        <div class="col col-5 categories" *ngFor="let item of allyDetail?.lesson_modules">
          <img [src]="item.image | safeHtml: 'url'" loading="lazy" class="rounded-circle img-circule img-category" alt="item.name">
          <p class="text-img affogato-medium">{{item.name}}</p>
        </div>
      </div>
      <div class="row justify-content-center text-center" *ngIf="ally && ally.name === 'Comex'">
        <div class="col col-12" *ngFor="let item of allyDetail?.lesson_modules">
          <p class="text-img affogato-medium">{{item.name}}</p>
        </div>
      </div>
    </div>
    <div class="col col-md-5 col-12">
      <img [src]="dataCategory.image | safeHtml: 'url'" loading="lazy" class="img-fluid" alt="image">
    </div>
  </div>

  <div class="row block-mobile justify-content-center align-items-center align-self-center">
    <div class="col col-12 text-center">
      <h5 class="title-curse affogato-bold" *ngIf="!ally || ally.name !== 'Comex'">Las categorías de nuestras lecciones son</h5>
      <h5 class="title-curse affogato-bold" *ngIf="ally && ally.name === 'Comex'">Y más lecciones basadas en:</h5>
    </div>
    <div class="col col-12">
        <img [src]="dataCategory.image | safeHtml: 'url'" loading="lazy" class="img-fluid" alt="image">
    </div>
    <div class="col col-12">  
      <div class="container">
        <div class="row justify-content-center text-center" *ngIf="!ally || ally.name !== 'Comex'">
          <div class="col col-5 categories" *ngFor="let item of allyDetail?.lesson_modules">
            <img [src]="item.image | safeHtml: 'url'" loading="lazy" class="rounded-circle img-circule img-category" alt="item.name">
            <p class="text-img affogato-medium">{{item.name}}</p>
          </div>
        </div>
        <div class="row justify-content-center text-center" *ngIf="ally && ally.name === 'Comex'">
          <div class="col col-12" *ngFor="let item of allyDetail?.lesson_modules">
            <p class="text-img affogato-medium">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button"
          routerLink="../registro"
          id="button-register">
    Regístrate <i class="fas fa-user"></i>
  </button>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-12 text-center">
        <h5 class="title-curse affogato-bold">¿Cómo funciona?</h5>
      </div>
      <div class="col col-12" *ngIf="ally">
        <video 
          width="100%" 
          height="auto"
          #playerVideo controls 
          [src]="getVideoUrl() | safeHtml: 'resourceUrl'" 
          type="video/mp4">
        </video>
      </div>
      <div class="col col-12" *ngIf="!ally">
        <video
          width="100%" 
          height="auto"
          #playerVideo 
          controls 
          controlsList="nodownload"
          [src]="dataCategory.video | safeHtml: 'resourceUrl'" 
          type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</div>


