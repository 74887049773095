import {faBuffer, faFacebook, faFacebookF, faFacebookSquare, faYoutube, faInstagram, faMailchimp} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faDesktop,
  faNewspaper,
  faPlayCircle,
  faSignInAlt,
  faTrophy,
  faAngleLeft,
  faCloudDownloadAlt,
  faAngleRight,
  faMinus,
  faPlus,
  faPlay,
  faPause,
  faHome,
  faEnvelope,
  faGlobe,
  faKey,
  faStore,
  faBriefcase
} from "@fortawesome/free-solid-svg-icons";
import {faHeart, faUser, faCheckCircle, faBuilding, faStar} from "@fortawesome/free-regular-svg-icons";

export const ICON = {
  FACEBOOK_F: faFacebookF,
  INSRAGRAM:faInstagram,
  FACEBOOK: faFacebook,
  FACEBOOK_SQUARE: faFacebookSquare,
  YOUTUBE: faYoutube,
  MAIL: faEnvelope,
  WEB: faGlobe,
  ARROW_LEFT: faArrowLeft,
  NEWSPAPER: faNewspaper,
  DESKTOP: faDesktop,
  USER: faUser,
  PLAY: faPlayCircle,
  FAVORITE: faHeart,
  STAR: faStar,
  LAYER: faBuffer,
  TROPHY: faTrophy,
  CLOSE: faSignInAlt,
  CHECK: faCheckCircle,
  DOWNLOAD:faCloudDownloadAlt,
  LEFT: faAngleLeft,
  RIGHT:faAngleRight,
  HOME: faHome,
  KEY: faKey,
  BRIEFCASE: faBriefcase,
  STORE: faStore
}

export const ICONPLAYER = {
  PLAY: faPlay,
  PAUSE: faPause,
  VOLUMEPLUS: faPlus,
  VOLUMEMINUS: faMinus
}
