import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { AllyModel } from '../../../models/ally/ally.model'
import { WebinarService } from 'src/app/services/webinars/webinar.service'
import { Webinar, WebinarResponse } from 'src/app/models/webinar/webinar.interface'
import { CountryModel } from 'src/app/models/country/country.model'
import { CatalogService } from 'src/app/services'
import { coppelCompanies } from 'src/app/constants/clients'
import { URLS } from 'src/app/constants/urls'

@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss']
})

export class BannerCarouselComponent implements OnInit {
  @Input() allyDetail: AllyModel
  assetsUrl: string
  arrayImage: any[] = []
  arrayImageMobile: any[] = []
  arrayCountry: CountryModel[] = []
  webinarComingUp: Webinar

  constructor (
    private readonly _webinarSrv: WebinarService,
    private readonly catalogService: CatalogService
  ) {
    this.assetsUrl = environment.url_S3assets
    this.getWebinarsComingUp()
  }

  ngOnChanges (changes: SimpleChanges): void {
    this.allyDetail = changes.allyDetail.currentValue
  }

  ngOnInit (): void {
    this.arrayImage = [
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_1.jpg` },
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_2.jpg` },
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_3.jpg` }
    ]
    this.arrayImageMobile = [
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_1.jpg` },
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_2.jpg` },
      { image: `${this.assetsUrl}Homepage_Login_Image/Carousel_M_3.jpg` }
    ]
    this.loadCountries()
  }

  loadCountries (): void {
    this.catalogService.getCountries().subscribe((countries) => {
      /* if (this.ally === 'encoppel') {
          countries.forEach((element) => {
            if (element.phone_prefix == '+52') this.arrayCountry.push(element);
          })
        } else {
          this.arrayCountry = countries;
        } */
      this.arrayCountry = countries
    })
  }

  getWebinarsComingUp (): void {
    this._webinarSrv.getUpComingWebinar().subscribe({
      next: (res: WebinarResponse) => {
        if (!res.detail.includes('No hay un webinar disponible')) {
          this.webinarComingUp = res.webinar[0]
        }
      }
    })
  }

  convertDateToHumanRead (fullDate: string): string {
    const dateSplitted = fullDate.split('T')
    const date = dateSplitted[0].split('-')
    const time = dateSplitted[1].split(':')
    return `${date[2]} de ${this.convertMonthToHumanRead(parseInt(date[1]))} del ${date[0]} a las ${time[0]}:${time[1]}`
  }

  convertMonthToHumanRead (month: number): string {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo',
      'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre',
      'Noviembre', 'Deciembre'
    ]
    return monthNames[month - 1]
  }

  getFlag (countryID: number): string {
    const country = this.arrayCountry.find((country) => country.id === countryID)
    return country.flag
  }

  getRouter (id: number): string {
    return `../live/webinar/${id.toString()}`
  }

  isCoppel (): boolean {
    if (this.allyDetail !== undefined ||
      this.allyDetail !== null) {
      const allyCode = `/${this.allyDetail.code}`
      return coppelCompanies.includes(allyCode)
    }
    return false
  }

  goToPension (): void {
    window.open(URLS.COPPEL_PENSION, '_blank', 'noopener noreferrer')
  }

  goToPlatform (): void {
    window.open(URLS.COPPEL_PLATFORM, '_blank', 'noopener noreferrer')
  }
}
