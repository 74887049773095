<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span class="color-close" aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col col-12">
                <video width="100%" height="400px" controls #videoInit>
                    <source src="https://static.enko.org/videos/1.+ENKO_Introducci%C3%B3n_V3.mp4" type="video/mp4" />
                    Tu navegador no soporta el video HTML5.
                </video>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-center d-flex justify-content-center">
    <button class="btn btn-primary" type="button" id="surveyButton" (click)="closeModal()">
        Continuar
    </button>
</div>
