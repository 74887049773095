
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {map, retry} from "rxjs/operators";
import {AchievementsModel, AchievementIdModel, Prize, AllyBusinessTypes} from '../../models/achievements/achievements.model';


@Injectable({
  providedIn: 'root'
})
export class AchievementsService {

  private readonly url: string;
  private urlAcelera: string;
  private tokenAcelera: string = "Lk8Pbn46nS85nDSni4Cvbskn5Clksh4";
 
  constructor(
    private http: HttpClient,
  ) {
    this.url = environment.url;
    this.urlAcelera = environment.url_service_acelera;

  }

  getAchievements(): Observable<AchievementsModel> {
    const url = `${this.url}/api/achievements/`;
    return this.http.get<AchievementsModel>(url)
  }

  getAchievementId(id: number): Observable<AchievementIdModel> {
    const url = `${this.url}/api/achievements/${id}/`;
    return this.http.get<AchievementIdModel>(url)
  }

  getPDFAcelera(latitude, longitude, name, business){
    let encodedName = encodeURI(name);
    let encodeBussiness = encodeURI(business)
    const url = `${ this.urlAcelera }?lat=${ latitude }&lng=${ longitude }&userid=111&nombre=${encodedName}&giro=${encodeBussiness}&wkey=${ this.tokenAcelera }`
    return this.http.get(url)
  }

  addAchivementUseData(id: number, userData:any){
    return this.http.post(`${ this.url }/api/achievements/${ id }/user_data/`, userData);
  }

  redeemCodeWebinar(id: number, codeData:any){
    return this.http.post(`${ this.url }/api/webinars/${ id }/redeem_code/`, codeData);
  }

  getPrize(idAchievement: number){
    const url = `${this.url}/api/achievements/${idAchievement}/prize_access/`;
    return this.http.get<Prize>(url).pipe(
      retry(1)
    );
  }

  
  getAchievementsUserData(idModule: number): Observable<Array<AllyBusinessTypes>>{
    return this.http.get<Array<AllyBusinessTypes>>(`${this.url}/api/lesson_modules/${idModule}/ally_business_types/`);
  }

}
