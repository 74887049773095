import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { RegisterFacebookModel, RegisterModel, RegisterSimpleModel } from '../../models/auth/register.model'
import { DATA_LOCALSTORAGE } from '../../utils/dataLocalstorage'
import { Observable, Subject } from 'rxjs'
import { ResetLoginPin, UserModel } from '../../models/auth/user.model'
import { CookieService } from 'ngx-cookie'
import { catchError } from 'rxjs/operators'
import { METHODS_OTPS } from 'src/app/constants/methodsOtps'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly url: string

  constructor (private readonly http: HttpClient, private readonly _cookieService: CookieService) {
    this.url = environment.url
  }

  registerUser (user: RegisterModel): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/register/`
    return this.http.post<UserModel>(url, user)
  }

  simpleRegisterUser (user: RegisterSimpleModel): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/simple_register/`
    return this.http.post<UserModel>(url, user)
  }

  registerUserFacebook (user: RegisterFacebookModel): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/fb_register/`
    return this.http.post<UserModel>(url, user)
  }

  signIn (phone_number?: string, login_pin?: string, email?: string, password?: string): Observable<UserModel> {
    const url = `${this.url}/api/${this._cookieService.get(DATA_LOCALSTORAGE.COUNTRY)}/login/`
    if (!password) {
      password = 'asd'
    }
    if (phone_number === null && login_pin === null) return this.http.post<UserModel>(url, { email, password })
    if (login_pin == null) return this.http.post<UserModel>(url, { phone_number, password })
    else return this.http.post<UserModel>(url, { phone_number, login_pin, password })
  }

  signInPath (phone_number?: string, login_pin?: string, email?: string, password?: string): Observable<UserModel> {
    const url = `${this.url}/api/mx/login/`
    if (!password) {
      password = 'asd'
    }
    if (phone_number === null && login_pin === null) return this.http.post<UserModel>(url, { email, password })
    if (login_pin == null) return this.http.post<UserModel>(url, { phone_number, password })
    else return this.http.post<UserModel>(url, { phone_number, login_pin, password })
  }

  sendCodeForWhatsApp (phone_number: string, code: string): Observable<UserModel> {
    const url = `${this.url}/api/wa_verification_code/`
    return this.http.post<UserModel>(url, { phone_number, code, method: METHODS_OTPS.WHATSAPP })
  }

  verifyCodeForWhatsApp (phone_number: string, code: string): Observable<UserModel> {
    const url = `${this.url}/api/wa_validation/`
    return this.http.post<UserModel>(url, { phone_number, verification_code: code })
  }

  resetLoginPin (phone_number?: string): Observable<ResetLoginPin> {
    const url = `${this.url}/api/mx/reset_login_pin/`
    return this.http.put<ResetLoginPin>(url, { phone_number })
  }

  hasPassword () {
    const url = `${this.url}/api/check_password/`
    return this.http.post(url, null)
  }

  recoveryPasswordByEmail (email: string) {
    const url = `${this.url}/api/send-reset-password/`
    const url_base = environment.front_url
    return this.http.post(url, { email, url_base })
  }

  verifyPhoneExist (phone: string) {
    const phoneWithoutPlus = phone.replace('+', '')
    const url = `${this.url}/api/verify-phone-number?phone_number=${phoneWithoutPlus}`
    return this.http.get(url)
  }

  recoveryPasswordByPhone (phone: string): Observable<Record<string, unknown>> {
    const url = `${this.url}/api/send-phone-token/`
    const url_base = environment.front_url
    return this.http.post<Record<string, unknown>>(url, { phone_number: phone, url_base })
  }

  updatePassword (password: string) {
    const url = `${this.url}/api/update_password/`
    return this.http.put(url, { password })
  }

  updatePasswordFromRecovered (password: string, token: string, uidb64: string) {
    const url = `${this.url}/api/set-new-password/`
    return this.http.patch(url, { password, token, uidb64 })
  }

  validateTokenRecoverPassword (token: string, uidb64: string) {
    const url = `${this.url}/api/verification-token/?token=${token}&uidb64=${uidb64}`
    return this.http.get(url).pipe(
      catchError((err) => {
        throw new Error(err.message) // lanzar una excepción con el mensaje de error recibido
      })
    )
  }
}
