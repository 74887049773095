import { Component, Input, OnInit } from '@angular/core'
import { coppelCompanies } from 'src/app/constants/clients'

import { URLS } from 'src/app/constants/urls'

@Component({
  selector: 'app-menu-responsive',
  templateUrl: './responsive.component.html',
  styleUrls: ['./responsive.component.scss']
})
export class ResponsiveComponent implements OnInit {
  @Input() landingTemplate: string
  @Input() shopMode: boolean

  ngOnInit (): void {
  }

  openWhatsapp (): void {
    const isCoppelAlly = coppelCompanies.includes(`/${this.landingTemplate}`)
    if (isCoppelAlly) {
      window.open(URLS.WHATSAPP_SUPPORT_COPPEL, '_blank', 'noopener noreferrer')
      return
    }
    window.open(URLS.WHATSAPP_SUPPORT_ENKO, '_blank', 'noopener noreferrer')
  }
}
