import { Component, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges, ViewChild, ElementRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AllyService, AuthService, ProfileService, StorageService, SweetAlertService } from '../../../services'
import { CookieService } from 'ngx-cookie'
import { AllyModel } from '../../../models/ally/ally.model'
import { environment } from '../../../../environments/environment'
import { ShopService } from 'src/app/services/shop/shop.service'
import { DATA_LOCALSTORAGE } from 'src/app/utils/dataLocalstorage'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { allies } from 'src/app/constants/clients'

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent implements OnInit {
  @Input() allyDetail: AllyModel
  s3UrlImage: string = environment.url_S3assets ?? ''
  country: string
  brand: string
  shopPoints: number = 0
  webinarPoints: number = 0
  shopMode: boolean
  webinar: any
  @ViewChild('buttonModalPassword') buttonModalPassword: ElementRef
  @ViewChild('buttonCloseModal') buttonCloseModal: ElementRef
  landingTemplate: string
  passwordForm: FormGroup

  constructor (
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _cookieService: CookieService,
    private readonly _storageService: StorageService,
    private readonly _shopService: ShopService,
    private readonly allyService: AllyService,
    private readonly authService: AuthService,
    private readonly sweetAlertSrv: SweetAlertService,
    private readonly _profileService: ProfileService
  ) {
    this.loadFormPassword()
  }

  loadFormPassword () {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
      confirm_password: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')])
    })
  }

  ngOnChanges (changes: SimpleChanges): void {
    this.allyDetail = changes.allyDetail.currentValue
  }

  ngOnInit (): void {
    this.shopMode = JSON.parse(this._cookieService.get(DATA_LOCALSTORAGE.SHOP_MODE)) ?? false
    this.route.paramMap.subscribe(params => {
      this.country = params.get('country')
      this.brand = params.get('brand')
      this.getInfoAlly()
    })
    if (this.shopMode) {
      this.getUserShopPoints()
      this.getWebinarPoints()
      this.updateEventSharedService()
    }
  }

  ngAfterViewInit (): void {
    this.authService.hasPassword().subscribe({
      next: (res: any) => {
        if (!res.data.hasPassword) {
          this.buttonModalPassword.nativeElement.click()
        }
      }
    })
  }

  isNotValidCamp (camp: string): boolean {
    return this.passwordForm.get(camp).invalid &&
      this.passwordForm.get(camp).touched
  }

  async closeSession (): Promise<void> {
    this._cookieService.removeAll()
    this._storageService.deleteAll()
    if (this.brand !== allies.ENKO && this.brand) {
      await this.router.navigate([`/${this.country}/${this.brand}`])
    } else {
      await this.router.navigate([`/${this.country}/enko`])
    }
  }

  getUserShopPoints (): void {
    this._shopService.getUserShopPoints().subscribe(
      res => {
        this.shopPoints = res.shop_points
      }
    )
  }

  getWebinarPoints (): void {
    this._profileService.getPersonalData().subscribe(
      res => {
        this.webinarPoints = res.webinars
      }
    )
  }

  /**
   * Services Shared for update any Component
   */
  updateEventSharedService (): void {
    this._shopService.changeEvent.subscribe(change => {
      this.getUserShopPoints()
    })
  }

  getInfoAlly (): void {
    this.allyService.getInfoOfAlly(this.brand).subscribe(res => {
      this.landingTemplate = res.landing_template
    }, error => {
      console.error(error)
    })
  }

  async savePassword (): Promise<void> {
    if (this.passwordForm.invalid) {
      return this.passwordForm.markAllAsTouched()
    }
    const password = this.passwordForm.get('password').value
    const confirmPassword = this.passwordForm.get('confirm_password').value
    if (password !== confirmPassword) {
      await this.sweetAlertSrv.basicSweet('Error', 'Las contraseñas no coinciden.', 'error')
      return
    }
    // Peticion backend
    this.authService.updatePassword(password).subscribe({
      next: (res) => {
        this.sweetAlertSrv.basicSweet('Éxito', 'Contraseña guardada correctamente.', 'success')
        this.buttonCloseModal.nativeElement.click()
      },
      error: (err) => {
        this.sweetAlertSrv.basicSweet('Error', 'Ha ocurrido un error al guardar la contraseña intentelo mas tarde.', 'error')
        this.buttonCloseModal.nativeElement.click()
      }
    })
  }

  isCoppel (): boolean {
    return this.landingTemplate === allies.COPPEL
  }
}
