<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3 class="affogato-bold">AVISO DE PRIVACIDAD</h3>
            <p class="proxima-nova">USUARIO DE LA PLATAFORMA DIGITAL ENKO <a href="https://www.enko.org/home/"
                    target="blank">(https://www.enko.org/home/)</a></p>
        </div>
        <div class="col-12">
            <ul>
                <li class="affogato-medium"><b>IDENTIDAD Y DOMICILIO DEL RESPONSABLE</b></li>
                <p class="proxima-nova"
                   *ngIf="!isVISA">FUNDACIÓN PARA EL DESARROLLO SOSTENIBLE EN MÉXICO S.C., <span *ngIf="!isCoppel">FUNDES Internacional S.A. y Asociación FUNDES,</span> en adelante FUNDES, con domicilio en
                    Calle Tlaxcala 25, interior 122, Roma Sur, Cuauhtémoc, Ciudad de México
                    , México; es el responsable del tratamiento
                    (recolección, almacenamiento, uso, procesamiento y comunicación) de sus datos personales.</p>
                <p class="proxima-nova"
                  *ngIf="isVISA">
                FUNDACIÓN PARA EL DESARROLLO SOSTENIBLE EN MÉXICO S.C., FUNDES Internacional S.A. y Asociación FUNDES, en adelante
                FUNDES, con domicilio en Av. Álvaro Obregón No. 171 Int. 610, col. Roma Norte, Cuauhtémoc, C.P. 06700, Ciudad de México
                , México; es el responsable del tratamiento (recolección, almacenamiento, uso, procesamiento y comunicación) de sus
                datos personales.
                </p>
                <li class="affogato-medium"><b>¿QUÉ DATOS PERSONALES RECABAMOS SOBRE USTED?</b></li>
                <p class="proxima-nova">Usted nos proporciona en forma libre e informada, directamente los siguientes datos personales, a
                    través de la Plataforma Digital de Desarrollo Empresarial <b>Enko:</b></p>
                <div class="list">
                    <ul>
                        <li class="proxima-nova">Nombre y apellidos.</li>
                        <li class="proxima-nova">Sexo.</li>
                        <li class="proxima-nova">Edad.</li>
                        <li class="proxima-nova">Nivel de educación.</li>
                        <li class="proxima-nova">Número de teléfono móvil.</li>
                        <li class="proxima-nova">Cuenta de correo electrónico y en su caso, número de cliente si ingresa a la plataforma a
                            través de una organización aliada. También, sin que sean considerados datos personales, le
                            solicitamos información de identificación de la empresa en la que labora, de la que es socio
                            o propietario.</li>
                    </ul>
                </div>
                <p class="proxima-nove"
                   *ngIf="isVISA">FUNDES no recopila datos personales sensibles como origen racial o étnico, estado de salud presente y futuro,
                información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, ni
                preferencia sexual.</p>
                <li class="affogato-medium"><b>¿PARA QUÉ FINALIDADES USAREMOS SUS DATOS PERSONALES?</b></li>
                <p class="proxima-nova">Las finalidades que se describen a continuación, dan origen y son necesarias para la existencia,
                    mantenimiento y cumplimiento de la relación que surge entre <b>FUNDES</b> y el Usuario de la
                    Plataforma:</p>
                <div class="list">
                    <ul>
                        <li class="proxima-nova">Darlo de alta en la plataforma;</li>
                        <li class="proxima-nova">Contactarlo para dar seguimiento y apoyo;</li>
                        <li class="proxima-nova">Informarle los logros que obtiene a medida que completa las lecciones;</li>
                        <li class="proxima-nova">Compartir la información con alguno de nuestros aliados <span *ngIf="isVISA">y posibles terceros interesados</span>, en caso de que hayan ingresado a la
                            plataforma a través de ellos; con el fin de que en interés del usuario, puedan llamarlos
                            para dar seguimiento de su evolución y desarrollo de los conocimientos adquiridos, al igual
                            que para ofrecerles productos y servicios que les ayuden a consolidar su aprendizaje y el
                            desarrollo de su organización.</li>
                    </ul>
                </div>
                <li class="affogato-medium"><b>¿CON QUIÉN TRANSFERIMOS SU INFORMACIÓN Y PARA QUE FINES?</b></li>
                <p class="proxima-nova">Le informamos que, dependiendo el país en el que resida el Usuario (persona física que accede a la
                    plataforma y es la titular de los datos personales), el tratamiento involucra la transferencia
                    nacional o internacional con organizaciones aliadas.
                    Lo anterior es necesario para mantener o cumplir la relación que surge entre <b>FUNDES</b> y el
                    Usuario, por la utilización de la plataforma <b>Enko</b>, al permitir fortalecer los recursos para
                    la operación y actualización de los contenidos de ésta.
                    Asimismo, la transferencia se lleva a cabo mediante la celebración de acuerdos en los que ofrezcan
                    condiciones en interés del usuario, mediante la oferta de productos y servicios <span *ngIf="isVISA">de todo tipo</span>, que les ayuden a
                    consolidar su aprendizaje y el desarrollo de su organización.
                    En ambos casos, no se requiere el consentimiento del Usuario.
                    Los terceros a los que puede transferirse los datos personales, son las autoridades de cualquier
                    naturaleza, de conformidad con lo dispuesto por la legislación aplicable y previo mandamiento
                    debidamente fundado y motivado; al igual que organizaciones aliadas de los sectores social, de
                    consumo, financiero<span *ngIf="isVISA">/bancario</span> y de tecnología de la información y comunicaciones, previa suscripción de
                    instrumentos legales que garanticen los derechos en materia de protección de datos personales,
                    reconocidos por la legislación mexicana.
                </p>
                <li class="affogato-medium"><b>¿CÓMO PUEDE ACCEDER, RECTIFICAR O CANCELAR SUS DATOS PERSONALES, U OPONERSE A SU USO?</b></li>
                <p class="proxima-nova">
                    Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las
                    condiciones de uso que les damos <b>(Acceso)</b>.
                    Asimismo, es su derecho solicitar la corrección de su información personal en caso de que este
                    desactualizada, sea inexacta o incompleta <b>(Rectificación)</b>; que la eliminemos de nuestros
                    registros o bases de datos;
                    cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y
                    obligaciones previstas en el marco jurídico aplicable <b>(Cancelación)</b>, así como oponerse al uso
                    de sus datos personales para fines específicos <b>(Oposición)</b>.
                    Estos derechos se conocen como derechos <b>ARCO</b>.
                </p>
                <p class="proxima-nova">
                    Para el ejercicio de cualquiera de los derechos <b>ARCO</b>, usted debe presentar la solicitud a la
                    siguiente cuenta de correo electrónico: <b>privacidad@fundes.org</b> a través del formulario que
                    ponemos a su disposición, dando click <a href="https://enko-solutions.s3.amazonaws.com/public_files/Aviso_de_privacidad_enko/Formato_Solicitud_ejercicio_Derechos_ARCO_FUNDES.docx">aquí</a>.
                </p>
                <p class="proxima-nova">
                    Con el fin de que podamos dar respuesta a su solicitud, usted o su representante legal, deberá
                    acreditar correctamente su identidad, acompañando una copia de alguna identificación vigente en los
                    términos señalados en el formulario.
                </p>
                <p class="proxima-nova">
                    En caso de que la información proporcionada sea errónea o insuficiente, o no se acompañen los
                    documentos de acreditación respectivos, dentro de los cinco días hábiles siguientes a la recepción
                    de la solicitud, se le requerirá que aporte los elementos o documentos necesarios para dar trámite a
                    la misma. Usted contará con diez días hábiles para atender el requerimiento, contados a partir del
                    día siguiente en que lo haya recibido.
                    De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente.
                </p>
                <p class="proxima-nova">
                    De cumplir con los requisitos se le comunicará en un plazo máximo de veinte días hábiles, contado
                    desde la fecha en que se recibió la solicitud, con el fin de que, en caso de resultar procedente,
                    haga efectiva la misma dentro de los quince días hábiles siguientes a que se comunique la respuesta.
                    La respuesta se dará vía electrónica a la dirección de correo electrónico que señale en el
                    formulario.
                </p>
                <p class="proxima-nova">
                    Los plazos referidos podrán ser ampliados una sola vez por un período igual, siempre y cuando así lo
                    justifiquen las circunstancias del caso y se le notificará a través de los medios de contacto
                    proporcionados.
                </p>
                <li class="affogato-medium"><b>¿CÓMO PUEDE REVOCAR SU CONSENTIMIENTO PARA EL USO DE SUS DATOS PERSONALES?</b></li>
                <p class="proxima-nova">
                    Usted puede revocar su consentimiento sin efectos retroactivos, que nos haya otorgado para el
                    tratamiento de sus datos personales. Sin embargo, es importante mencionarle que la revocación de su
                    consentimiento, implicará que no podrá seguir usando la plataforma.
                    El procedimiento para revocar su consentimiento, es el mismo establecido para el ejercicio de sus
                    derechos <b>ARCO</b>.
                </p>
                <li class="affogato-medium"><b>¿COMO PUEDE LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?</b></li>
                <p class="proxima-nova">puede limitar el uso o divulgación de sus datos personales, utilizados para fines no descritos en
                    este Aviso de Privacidad, enviando su solicitud a la siguiente cuenta de correo electrónico
                    <b>privacidad@fundes.org</b>.
                    Los requisitos para acreditar su identidad, así como el procedimiento para atender su solicitud, se
                    regirán por los mismos criterios señalados en el apartado anterior.
                </p>
                <li class="affogato-medium"><b>¿CÓMO PUEDE CONOCER LOS CAMBIOS A ESTE AVISO DE PRIVACIDAD?</b></li>
                <p class="proxima-nova">El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de
                    nuevos requerimientos legales, de nuestras propias necesidades en cuanto a reestructuraciones o
                    modificaciones estatutarias, de nuestro modelo de negocio, de las prácticas de privacidad o por
                    otras causas.
                    Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de
                    Privacidad a través de nuestro del portal digital, cuya dirección es <a href=""
                        target="blank">https://www.enko.org</a>. Le recomendamos visitar periódicamente la página con la
                    finalidad de informarse sobre si ocurre un cambio al presente.
                </p>
                <li class="affogato-medium"><b>¿CUÁNDO Y CÓMO LE INFORMAREMOS SOBRE UN NUEVOS AVISO DE PRIVACIDAD?</b></li>
                <p>En caso de que haya cambio de identidad del responsable, se requiera recabar datos personales
                    sensibles, patrimoniales o financieros adicionales a aquellos señalados en este Aviso de Privacidad,
                    cambien las finalidades que dieron origen o son necesarias para la relación entre <b>FUNDES</b> y el
                    usuario, o se incorporen nuevas que requieran del consentimiento del titular,
                    se modifiquen las transferencias o se vayan a realizar transferencias no previstas inicialmente y
                    sea necesario obtener su consentimiento,
                    nos comunicaremos con usted a través de su cuenta de correo electrónico,
                    con el fin de informarle sobre cualquiera de las situaciones citadas.
                </p>
                <li class="affogato-medium"><b>¿AUTORIDAD EN MATERIA DE PROTECCIÓN DE DATOS PERSONALES</b></li>
                <p class="proxima-nova">Si usted considera que su derecho a la protección de datos personales ha sido lesionado por alguna
                    conducta u omisión de nuestra parte,
                    o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos
                    Personales en Posesión de Particulares,
                    su Reglamento y demás disposiciones aplicables,
                    podrá interponer su inconformidad o denuncia Ante el Instituto Nacional de Transparencia, Acceso a
                    la Información y Protección de Datos Personales <span *ngIf="!isCoppel">de Costa Rica</span>.
                    <span *ngIf="isCoppel">Para mayor información, le sugerimos visitar su página oficial de Internet <a href="www.inai.org.mx"
                        target="blank">www.inai.org.mx</a></span>
                    <span *ngIf="isCoppel">Para mayor información, le sugerimos visitar su página oficial de Internet <a
                            href="www.inai.org.mx" target="blank">www.inai.org.mx</a></span>
                </p>
                <li class="affogato-medium"><b>JURISDICCIÓN APLICABLE</b></li>
                <p class="proxima-nova"><b>FUNDES</b> y el Usuario aceptan que para la interpretación y cumplimiento de este Aviso de
                    Privacidad,
                    así como en lo no previsto en el mismo, se someten a las normas de contenido,
                    jurisdicción y competencia de autoridad garante y jurisdiccionales previstas en la legislación y
                    disposiciones administrativas, en materia de Protección de Datos Personales en Posesión de
                    Particulares aplicable en {{ isCoppel ? 'México' : 'Costa Rica'}},
                    renunciando a cualquier fuero que pudiera corresponderles en razón de su domicilio presente o
                    futuro.
                </p>
            </ul>
        </div>
    </div>
</div>