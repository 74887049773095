<div class="card-lesson">
 
  <img class="card-lesson-img cursor-pointer"
       [src]="lesson.image | safeHtml:'url'" 
       alt="Card image cap"
       (click)="navigateToDetail(lesson)">
  
  <div class="card-body">
    <div class="row cursor-pointer"
         (click)="navigateToDetail(lesson)">
      <div class="col-12">
        <p class="title-lesson">{{ lesson.title }}</p>
      </div>
    </div>

    <div class="row pt-1 cursor-pointer" 
         (click)="navigateToDetail(lesson)">
      <div class="col-8">
        <p class="d-flex align-items-center text-title-module">
          <span class="dot" 
                [style.background-color]="lesson.module_color"></span>
          {{ lesson.module_name }}
        </p>
      </div>

      <div class="col-4">
        <p class="float-right font-bold">{{ lesson.time }}.</p>
      </div>
    </div>

   <div class="row align-items-center pt-3 cursor-pointer"
        *ngIf="lesson.percentage !== 0"
        (click)="navigateToDetail(lesson)">
      <div class="col-12 text-center font-bold">{{ lesson.percentage }}%</div>
      <div class="col-12">
        <div class="progress">
          <div class="progress-bar bg-success" 
               role="progressbar" 
               [style.width]="lesson.percentage + '%'" 
               aria-valuenow="25" 
               aria-valuemin="0" 
               aria-valuemax="100"></div>
        </div>
      </div>
   </div>
   <div class="row align-items-center pt-3">
    <div class="col-5">
        <span class="favicon">
          <fa-icon [icon]="iconStar.fav_solid"></fa-icon>
        </span>
      <span class="lesson-rating">{{ lesson.rating | number:'1.1-1' }}</span>
    </div>
    <div class="col-2">
        <span class="favicon">
          <fa-icon [icon]="icon.fav" 
                   *ngIf="!lesson.favorite"
                   class="animate__animated animate__fadeIn icon-favorite"
                   (click)="favoriteLesson(lesson)"></fa-icon>
          <fa-icon [icon]="icon.fav_solid" 
                   *ngIf="lesson.favorite"
                   class="animate__animated animate__fadeIn icon-favorite"
                   (click)="favoriteLesson(lesson)"></fa-icon>
        </span>
    </div>
    <div class="col-5">
      <p class="favicon-users">
        {{ lesson.users_who_rated }} 
        <fa-icon [icon]="iconUser"></fa-icon>
      </p>
    </div>
   </div>
   <div class="row button-start-now"
        *ngIf="lesson.percentage === 0"
        (click)="navigateToDetail(lesson)">
    <div class="col-12">
      <button class="btn btn-primary btn-block">
          ¡Iniciar ahora!
      </button>
    </div>
   </div>
  </div>
</div>