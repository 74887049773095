export const minecoCompanies: string[] = ['/oportunidad']

export const ladasYucatan: string[] = [
  '988',
  '997',
  '991',
  '999',
  '985',
  '986',
  '969'
]

export const coppelCompaniesTerms: string[] = [
  '/businesswomen',
  '/ammje',
  '/leccionescoppel',
  '/tualiada',
  '/ceip',
  '/ccmx',
  '/atienda',
  '/colaboradores',
  '/coppel',
  '/enko',
  '/mujerenpotencia',
  '/nuevoleon',
  '/oaxacaemprende',
  '/encoppel',
  '/coppelemprende',
  '/coppelchiapas',
  '/coppelqueretaro',
  '/esteticas',
  '/coppelclientes'
]

export const coppelCompanies: string[] = [
  '/businesswomen',
  '/ammje',
  '/leccionescoppel',
  '/tualiada',
  '/ceip',
  '/ccmx',
  '/atienda',
  '/colaboradores',
  '/coppel',
  '/mujerenpotencia',
  '/nuevoleon',
  '/oaxacaemprende',
  '/encoppel',
  '/coppelemprende',
  '/coppelchiapas',
  '/coppelqueretaro',
  '/esteticas',
  '/coppelclientes'
]

export const allies = {
  BANCO_INDUSTRIAL: 'bancoindustrial',
  BANCO_SANTA_CRUZ: 'bancosantacruz',
  GEEK_GIRLS_LATAM: 'geekgirlslatam',
  BILLU: 'billu',
  BANRESERVAS: 'banreservas',
  COPPEL_EMPRENDE: 'coppelemprende',
  ATIENDA: 'atienda',
  MUJER_EN_POTENCIA: 'mujerenpotencia',
  BANCO_NORTE: 'banorte',
  GRUPO_MODELO: 'grupomodelo',
  EMPRESARIAS_MODELO: 'empresariasmodelo',
  COMEX: 'comex',
  CRECIENDO_JUNTAS: 'creciendojuntas',
  COPPEL: 'coppel',
  COMPARTFON: 'compartfon',
  BANCO_COMPARTAMOS: 'compartamosbanco',
  ENCOPPEL: 'encoppel',
  ENKO: 'enko',
  BANORTE: 'banorte',
  ANSER: 'anser',
  UNI_TEC: 'tec',
  PREPA_TEC: 'prepatec',
  SCOTIABANK_CHILE: 'scotiabankchile'
}

export const DEACTIVATE_ALLIES = [
  'compartamosbanco',
  'compartfon',
  'srpago',
  'srpagoweb',
  'grupomodelo',
  'konfio',
  'konsiento',
  'comex',
  'bavaria',
  'yastas',
  'credibanco',
  'bancocajasocial',
  'prosoli'
]

export const VISA_ALLIES = [
  'visa',
  'banreservas',
  'mipymedigital',
  'oportunidad',
  'woccu',
  'woccuperu',
  'AMPYME',
  'creciendojuntas',
  'capacitatechiapas',
  'campeche',
  'billu',
  'billu_capacitacion',
  'bancosantacruz',
  'scotiabank',
  'tabasco',
  'chihuahua',
  'micropitch',
  'geekgirlslatam',
  'plurall',
  'bancoindustrial',
  'makesense',
  'aavance',
  'banreservas',
  'apap',
  'veracruz',
  'integral',
  'anser'
]
