import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { json } from 'express';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  set(key: string, data: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  get(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      let json = localStorage.getItem(key);
      if (typeof json == 'undefined') {
        return '';
      }
      return JSON.parse(json);
    }
  }

  delete(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  deleteAll() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
}
