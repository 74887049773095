<nav id="bar-auth"
     class="navbar nav__bg">
  <div class="content-navbar">
    <a class="navbar-brand nav--text"
       [routerLink]="['./inicio']"
       *ngIf="!allyDetail">
      <img src="{{s3UrlImage}}Logos/logo-enko-gray.png"
           loading="lazy"
           class="logo-enko"
           alt="Logo Enko">
    </a>
    <a class="navbar-brand nav--text"
       [routerLink]="['./inicio']"
       *ngIf="allyDetail && allyDetail.navbar_enko_logo_enabled">
      <img src="{{s3UrlImage}}Logos/logo-enko-gray.png"
           loading="lazy"
           class="logo-enko"
           alt="Logo Enko">
    </a>
    <a class="{{isCoppel() ? 'navbar-brand-ally-coppel': 'navbar-brand-ally'}}"
       [routerLink]="['inicio']"
       *ngIf="allyDetail && !allyDetail.second_logo">
      <img [src]="allyDetail.logo"
           loading="lazy"
           class="{{isCoppel() ? 'logo-ally-coppel': 'logo-ally'}}"
           alt="Enko">
    </a>
    <a class="{{isCoppel() ? 'navbar-brand-ally-coppel': 'navbar-brand-ally'}}"
       [routerLink]="['inicio']"
       *ngIf="allyDetail && allyDetail.second_logo">
      <img [src]="allyDetail.second_logo"
           loading="lazy"
           class="{{isCoppel() ? 'logo-ally-coppel': 'logo-ally'}}" alt="Enko">
    </a>
    <div class="buttons">
      <button type="button"
              routerLinkActive="active"
              routerLink="inicio">
        <p>Inicio</p> <i class="fas fa-home"></i>
      </button>
      <button type="button"
              routerLinkActive="active"
              routerLink="lecciones">
        <p>Lecciones</p> <i class="fa-solid fa-person-chalkboard"></i>
      </button>
      <button type="button"
              routerLinkActive="active"
              routerLink="logros">
        <p>{{ shopMode ? 'Tienda' : 'Logros' }}</p> <i class="fa-solid fa-store"></i>
      </button>
      <button type="button"
              routerLinkActive="active"
              routerLink="perfil">
        <p>Perfil</p> <i class="fa-solid fa-user-gear"></i>
      </button>
    </div>
  </div>

</nav>

<!-- Modal Password -->
<!-- Button trigger modal -->
<button type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#staticBackdrop"
        #buttonModalPassword
        style="display: none !important;">
</button>

<!-- Modal -->
<div class="modal fade"
     id="staticBackdrop"
     data-backdrop="static"
     data-keyboard="false"
     tabindex="-1"
     aria-labelledby="staticBackdropLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"
            id="staticBackdropLabel">Crear contraseña</h5>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                style="display: none !important;"
                #buttonCloseModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2>Protege tu cuenta!</h2>
        <p>Establece una contraseña para tu cuenta.</p>
        <p>Se te solicitará la contraseña cada vez que inicies sesión.</p>
        <hr>
        <form [formGroup]="passwordForm">
          <div class="form-group">
            <input type="password"
                   class="form-control"
                   maxlength="4"
                   [class.is-invalid]="isNotValidCamp('password')"
                   aria-describedby="middle_name"
                   formControlName="password"
                   placeholder="Contraseña"/>
            <div class="invalid-feedback"
                 *ngIf="isNotValidCamp('password')">La contraseña debe ser 4 números.
            </div>
          </div>
          <div class="form-group">
            <input type="password"
                   class="form-control"
                   [class.is-invalid]="isNotValidCamp('confirm_password')"
                   maxlength="4"
                   aria-describedby="middle_name"
                   formControlName="confirm_password"
                   placeholder="Confirmar Contraseña"/>
            <div class="invalid-feedback"
                 *ngIf="isNotValidCamp('confirm_password')">La contraseña debe ser 4 números y
              coincidir con la anterior.
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-primary"
                (click)="savePassword()">Guardar
        </button>
      </div>
    </div>
  </div>
</div>

<app-menu-responsive id="menu-responsive"
                     [landingTemplate]="landingTemplate"
                     [shopMode]="shopMode"></app-menu-responsive>