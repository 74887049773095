import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Modules
import { CookieModule } from 'ngx-cookie'
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons'
import { ShareIconsModule } from 'ngx-sharebuttons/icons'
import { NgxSpinnerModule } from 'ngx-spinner'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AgmCoreModule } from '@agm/core'
// Service
import { BlogService } from '../services'

// Pipe
import { SafeHtmlPipe } from './pipes/safe-html.pipe'

// Components
import { HeaderComponent } from './components/header/header.component'
import { BlogComponent } from './components/blog/blog.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { FooterComponent } from './components/footer/footer.component'
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component'
import { QuicklinkModule } from 'ngx-quicklink'
import { BannerComponent } from './components/banner/banner.component'
import { BlockCourseComponent } from './components/block-course/block-course.component'
import { BlockCategoryComponent } from './components/block-category/block-category.component'
import { SliderHistoryComponent } from './components/slider-history/slider-history.component'
import { SliderLessonsComponent } from './components/slider-lessons/slider-lessons.component'
import { HeaderAuthComponent } from './components/header-auth/header-auth.component'
import { FooterAuthComponent } from './components/footer-auth/footer-auth.component'
import { CardLessonComponent } from './components/card-lesson/card-lesson.component'
import { FilesPartComponent } from './components/files-part/files-part.component'
import { RatingPartComponent } from './components/rating-part/rating-part.component'
import { DatepickerComponent } from './components/datepicker/datepicker.component'
import { ModalAchievementComponent } from './components/modal-achievement/modal-achievement.component'
import { ListAchievementsComponent } from './components/list-achievements/list-achievements.component'
import { BannerCarouselComponent } from './components/banner-carousel/banner-carousel.component'
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component'
import { NoticePrivacyComponent } from './components/notice-privacy/notice-privacy.component'
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component'
import { BlockExtraComponent } from './components/block-extra/block-extra.component'
import { SliderTestimonialsComponent } from './components/slider-testimonials/slider-testimonials.component'
import { CardTestimonialsComponent } from './components/card-testimonials/card-testimonials.component'
import { ModalTestimonialsComponent } from './components/modal-testimonials/modal-testimonials.component'
import { ModalSurveyComponent } from './components/modal-survey/modal-survey.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { ResponsiveComponent } from './components/header-auth/menu-responsive/responsive.component'
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component'

@NgModule({
  declarations: [
    HeaderComponent,
    BlogComponent,
    NotFoundComponent,
    FooterComponent,
    BlogDetailComponent,
    SafeHtmlPipe,
    BannerComponent,
    BlockCourseComponent,
    BlockCategoryComponent,
    SliderHistoryComponent,
    SliderLessonsComponent,
    HeaderAuthComponent,
    FooterAuthComponent,
    CardLessonComponent,
    FilesPartComponent,
    DatepickerComponent,
    RatingPartComponent,
    DatepickerComponent,
    ModalAchievementComponent,
    ListAchievementsComponent,
    BannerCarouselComponent,
    TermsConditionComponent,
    NoticePrivacyComponent,
    CookiesPolicyComponent,
    BlockExtraComponent,
    SliderTestimonialsComponent,
    CardTestimonialsComponent,
    ModalTestimonialsComponent,
    ModalSurveyComponent,
    ResponsiveComponent,
    MaintenancePageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    QuicklinkModule,
    FontAwesomeModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgSelectModule,
    CookieModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAuqwHD98poOkSVAnuPrLWSmtndlQLLCok',
      libraries: ['places']
    })
  ],
  exports: [
    HeaderComponent,
    BlogComponent,
    NotFoundComponent,
    FooterComponent,
    BlogDetailComponent,
    SafeHtmlPipe,
    BannerComponent,
    BlockCourseComponent,
    BlockCategoryComponent,
    SliderHistoryComponent,
    SliderLessonsComponent,
    HeaderAuthComponent,
    FooterAuthComponent,
    CardLessonComponent,
    FilesPartComponent,
    RatingPartComponent,
    DatepickerComponent,
    ListAchievementsComponent,
    BannerCarouselComponent,
    TermsConditionComponent,
    NoticePrivacyComponent,
    CookiesPolicyComponent,
    BlockExtraComponent,
    SliderTestimonialsComponent,
    CardTestimonialsComponent,
    MaintenancePageComponent
  ],
  providers: [
    BlogService
  ]
})
export class SharedModule {
}
