<div class="row">
    <div class="col col-12 carousel-desktop">
        <div id="carouselExampleControls" 
             class="carousel slide" 
             data-ride="carousel">

            <div class="carousel-inner" 
                 *ngIf="!allyDetail">
                <div class="carousel-item" 
                     *ngFor="let banner of arrayImage; let index = index;let isFirst = first"
                     [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" 
                         [src]="banner.image"
                         alt="banner">
                </div>
            </div>

            <div class="carousel-inner" 
                 *ngIf="allyDetail">
                <div class="carousel-item" 
                     *ngIf="isCoppel()" 
                     style="cursor: pointer;"
                     (click)="goToPlatform()"
                     [ngClass]="{active:true}">
                    <img class="d-block w-100" src="../../../../assets/imgs/banner-platform-desktop.png" alt="banner">
                </div>
                <div class="carousel-item" 
                     *ngIf="isCoppel()" 
                     style="cursor: pointer;"
                     (click)="goToPension()">
                    <img class="d-block w-100" src="../../../../assets/imgs/Banner_Ordenador_calculadora.jpg" alt="banner">
                </div>
                <!-- Carousel Webinar -->
                 <div class="carousel-item"
                      *ngIf="webinarComingUp && webinarComingUp.banner_logo"
                      style="cursor: pointer;"
                      [ngClass]="{active:true && !isCoppel()}"
                      [routerLink]="getRouter(webinarComingUp.id)">
                    <img class="d-block w-100" 
                         [src]="webinarComingUp.banner_logo" 
                         alt="banner">
                 </div>
                <!-- Carousel When the ally has banners -->
                <ng-container *ngIf="allyDetail.desktop_carousel_images.length > 0">
                    <div class="carousel-item"
                         *ngFor="let ally of allyDetail.desktop_carousel_images; let index = index;let isFirst = first"
                         [ngClass]="{active:isFirst && !webinarComingUp && !isCoppel()}">
                        <img class="d-block w-100"
                             [src]="ally.image"
                             alt="banner">
                    </div>
                </ng-container>
                <!-- Carousel When the ally does not have banners -->
                <ng-container *ngIf="allyDetail.desktop_carousel_images.length === 0">
                    <div class="carousel-item"
                         *ngFor="let banner of arrayImage; let index = index;let isFirst = first"
                         [ngClass]="{active:isFirst && !webinarComingUp && !isCoppel()}">
                        <img class="d-block w-100"
                             [src]="banner.image"
                             alt="banner">
                    </div>
                </ng-container>                
            </div>

            <a class="carousel-control-prev" 
               href="#carouselExampleControls" 
               role="button" 
               data-slide="prev">
                <span class="carousel-control-prev-icon" 
                      aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" 
               href="#carouselExampleControls" 
               role="button" 
               data-slide="next">
                <span class="carousel-control-next-icon" 
                      aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <!-- Carousel Mobile -->
    <div class="col col-12 carousel-mobile">
        <div id="carouselControlsMobile" 
             class="carousel slide" 
             data-ride="carousel">
            <div class="carousel-inner"
                 *ngIf="!allyDetail">
                <div class="carousel-item" 
                     *ngFor="let banner of arrayImageMobile; let index = index;let isFirst = first" 
                     [ngClass]="{active:isFirst}">
                    <img class="d-block w-100" 
                         [src]="banner.image" 
                         alt="banner">
                </div>
            </div>
            <div class="carousel-inner" 
                 *ngIf="allyDetail">

                <div class="carousel-item" 
                     *ngIf="isCoppel()" 
                     style="cursor: pointer;"
                     (click)="goToPlatform()"
                     [ngClass]="{active:true}">
                    <img class="d-block w-100" src="../../../../assets/imgs/banner-platform-mobile.png" alt="banner">
                </div>
                <div class="carousel-item" 
                     *ngIf="isCoppel()" 
                     style="cursor: pointer;"
                     (click)="goToPension()">
                    <img class="d-block w-100" src="../../../../assets/imgs/Banner_Phone_calculadora.jpg" alt="banner">
                </div>

                <!-- Webinar -->
                <div class="carousel-item"
                     *ngIf="webinarComingUp"
                     style="cursor: pointer;"
                     [ngClass]="{active:true && !isCoppel()}"
                     [routerLink]="getRouter(webinarComingUp.id)">
                    <!-- Webinar -->
                    <img class="d-block w-100" 
                         [src]="webinarComingUp.banner_logo" 
                         alt="banner">
                 </div>

                <!-- Carousel When the ally has banners -->
                <ng-container *ngIf="allyDetail.desktop_carousel_images.length > 0">
                    <div class="carousel-item"
                         *ngFor="let ally of allyDetail.desktop_carousel_images; let index = index;let isFirst = first"
                         [ngClass]="{active:isFirst && !webinarComingUp && !isCoppel()}">
                        <img class="d-block w-100"
                             [src]="ally.image"
                             alt="banner">
                    </div>
                </ng-container>
                <!-- Carousel When the ally does not have banners -->
                <ng-container *ngIf="allyDetail.desktop_carousel_images.length === 0">
                    <div class="carousel-item"
                         *ngFor="let banner of arrayImageMobile; let index = index;let isFirst = first"
                         [ngClass]="{active:isFirst && !webinarComingUp}">
                        <img class="d-block w-100"
                             [src]="banner.image"
                             alt="banner">
                    </div>
                </ng-container>

            </div>
            <a class="carousel-control-prev" 
               href="#carouselControlsMobile" 
               role="button"
               data-slide="prev">
                <span class="carousel-control-prev-icon" 
                      aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" 
               href="#carouselControlsMobile" 
               role="button" 
               data-slide="next">
                <span class="carousel-control-next-icon" 
                      aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>
