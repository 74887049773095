<section id="bar" 
     class="navbar nav__bg">
  <a class="navbar-brand"
     [routerLink]="['inicio']"
     *ngIf="!allyDetail || (allyDetail && allyDetail.navbar_enko_logo_enabled)">
    <img [src]="getDefaultImage()" 
         loading="lazy" 
         class="logo-enko" 
         alt="Logo Enko" />
  </a>
  <a class="{{ isCoppel() ? 'navbar-brand-coppel' : 'navbar-brand-ally' }}"
     [routerLink]="['inicio']"
     *ngIf="allyDetail">
    <img [src]="allyDetail.logo" 
         loading="lazy" 
         class="logo-ally" 
         alt="Enko" />
  </a>
  <button type="button"
          routerLink="login">
    Iniciar sesión <i class="fas fa-user"></i>
  </button>
</section>
