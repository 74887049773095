import {Component, Input, OnInit} from '@angular/core';
import {AllyModel} from "../../../models/ally/ally.model";
import {HOME_LABELS} from "../../../utils/labels";
import {ActivatedRoute, Router} from "@angular/router";
import { environment } from ".././../../../environments/environment";
import { AllyService } from 'src/app/services';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() ally: AllyModel;
  brand:string;
  country:string;
  landingTemplate:string;

  banner: {title: string, description: string, button: string, img: string} = {
    title: HOME_LABELS.BANNER_TITLE,
    description: HOME_LABELS.BANNER_DESCRIPTION,
    button: HOME_LABELS.BANNER_TEXT_BUTTON,
    img: `${environment.url_S3assets}Homepage_Images/principal_image.jpg`
  }

  constructor(
    private _router: Router,
    private _routerActivate: ActivatedRoute,
    private allyService: AllyService,
  ) {
    this._routerActivate.paramMap.subscribe(params => {
      this.country = params.get('country');
      this.brand = params.get('brand');
    })
   }

  ngOnInit(): void {
    this.loadDataAlly();
  }

  goRegister() {
    this._router.navigate(['../registro'], {relativeTo: this._routerActivate})
  }

  loadDataAlly() {
    this.allyService.getInfoOfAlly(this.brand).subscribe(res => {
      this.landingTemplate = res.landing_template;
    })
  }

}
