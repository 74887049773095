import { Component, OnInit, Input } from '@angular/core';
import { HOME_LABELS, 
          HOME_DEFAULT_COURSE, 
          HOME_COMEX_COURSE, 
          HOME_COMPARTAMOS_COURSE, 
          HOME_MOODELO_COURSE, 
          HOME_COPPEL_COURSE,
          HOME_OLEODUCTO_COURSE} from "../../../utils/labels";
import { ICON } from "../../../utils/icons";
import { AllyModel } from "../../../models/ally/ally.model";
import { environment } from ".././../../../environments/environment";
import { AllyService } from 'src/app/services';

interface courses {
  title: string;
  title_coppel: string;
  title_oleoducto: string;
  title_banorte: string;
  image:string;
  text_button: string;
  icon_button: any;
  courses: Array<course>;
}
interface course {
  img: string;
  text: string;
}
@Component({
  selector: 'app-block-course',
  templateUrl: './block-course.component.html',
  styleUrls: ['./block-course.component.scss']
})
export class BlockCourseComponent implements OnInit {

  @Input() ally: AllyModel;

  dataCourses: courses = {
    title: HOME_LABELS.TITLE_COURSE,
    title_coppel: HOME_LABELS.TITLE_COURSE_COPPEL,
    title_oleoducto: HOME_LABELS.TITLE_COURSE_OLEODUCTO,
    title_banorte: HOME_LABELS.TITLE_COURSE_BANORTE,
    image: `${environment.url_S3assets}Homepage_Images/principal_course_image.jpg`,
    text_button: HOME_LABELS.BUTTON_WATCH_HOW_TO_WORK,
    icon_button: ICON.PLAY,
    courses: [
    ],
  }

  landingTemplate:string;

  constructor(
    private allyService: AllyService,
  ) { 
    this.dataCourses.courses = []
  }

  ngOnInit(): void {
    this.getInfoAlly();
    if(this.ally){
      this.getCourseByAlly();
    }else{
      this.dataCourses.courses = HOME_DEFAULT_COURSE;
    }
  }

  getCourseByAlly(){
    if( this.ally.code != 'comex'){
      this.dataCourses.courses = HOME_DEFAULT_COURSE
    }
    if(this.ally.code === 'comex'){
      this.dataCourses.courses = HOME_COMEX_COURSE
    }
    if(this.ally.code === 'compartfon' || this.ally.code === 'compartamosbanco'){
      this.dataCourses.courses = HOME_COMPARTAMOS_COURSE
    }
    if(this.ally.code === 'grupomodelo'){
      this.dataCourses.courses = HOME_MOODELO_COURSE
    }
    if(this.landingTemplate === 'coppel'){
      this.dataCourses.courses = HOME_COPPEL_COURSE
    }
    if(this.ally.code === 'oleoductovivo'){
      this.dataCourses.courses = HOME_OLEODUCTO_COURSE
    }
  }

  getInfoAlly() {
    this.allyService.getInfoOfAlly(window.location.href.split('/')[4]).subscribe(res => {
      this.landingTemplate = res.landing_template;
    },error=>{
      console.error(error);
    });
  }
  
}
