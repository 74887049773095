import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'

// Routing
import { AppRoutingModule } from './app-routing.module'

// Modules
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { SharedModule } from './shared/shared.module'
import { CookieModule } from 'ngx-cookie'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { QuicklinkModule } from 'ngx-quicklink'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserStateInterceptor } from './core/interceptor/browserstate.interceptor'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'Enko' }),
    AppRoutingModule,
    HttpClientModule,
    QuicklinkModule,
    SharedModule,
    FontAwesomeModule,
    NoopAnimationsModule,
    CarouselModule,
    BrowserTransferStateModule,
    CookieModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
