import { Component, Input, OnInit } from '@angular/core'
import { OwlOptions } from 'ngx-owl-carousel-o'
import { ModuleModel } from 'src/app/models/module/module.model'
import { LessonRecommendationModel } from '../../../models/lesson/lesson.model'

@Component({
  selector: 'app-slider-lessons',
  templateUrl: './slider-lessons.component.html',
  styleUrls: ['./slider-lessons.component.scss']
})
export class SliderLessonsComponent implements OnInit {
  @Input() arrayLessons: LessonRecommendationModel[]
  @Input() explore: boolean
  @Input() unblocked: boolean = true
  @Input() moduleData: ModuleModel

  customOptions: OwlOptions = {
    loop: false,
    autoWidth: true,
    autoplay: false,
    autoplayHoverPause: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    mergeFit: false,
    navText: ['<', '>'],
    stagePadding: 50,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      500: {
        items: 2,
        nav: false
      },
      860: {
        items: 3,
        nav: false
      },
      1100: {
        items: 4,
        nav: true
      }
    }
  }

  constructor () { }

  ngOnInit (): void {
  }
}
