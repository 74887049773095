<div class="row" *ngIf="shopMode === 'true'; else elseBlock">
    <div class="col col-6 col-xs-6 col-md-3 col-lg-3 col-xl-3" *ngFor="let achievement of achievements">
        <div class="text-center achievement">
            <img class="image" [src]="achievement.image" alt="imagen-logro">
            <div class="card-body" style="min-height: 160px;">
                <p style="font-weight: bold;">{{achievement.name}}</p>
                <p class="title-keys"><img style="height: 15px; margin-top: -15px;" src="https://static.enko.org/public_files/encoppel/llaves/llave_coppel-01.svg" alt="Felicidades"> {{achievement.shop_points}} llaves</p>
                <p class="title-keys-remaining" style="margin-top: -10px; margin-bottom: -10px;" *ngIf="achievement.min_webinars > 0">+ {{achievement.min_webinars}} Webinar</p>
            </div>
            <div>
                <button (click)="modalAchievement(achievement.id)" 
                        class="btn" 
                        [disabled]="disabledBtn" 
                        style="margin-top: -10px; background-color: #1294D6 !important;"  
                        *ngIf="achievement.remaining_shop_points == null && achievement.unblocked">
                        Ver premio
                </button>
                <button (click)="modalAchievement(achievement.id)" 
                        class="btn" 
                        [disabled]="disabledBtn" 
                        style="margin-top: -10px;"  
                        *ngIf="achievement.remaining_shop_points == null && !achievement.unblocked">
                        Canjear ahora
                </button>
                <button (click)="modalAchievement(achievement.id)" 
                        class="btn-outline" 
                        [disabled]="disabledBtn" 
                        style="margin-top: -10px;" 
                        *ngIf="achievement.remaining_shop_points != null">
                        Te faltan {{achievement.remaining_shop_points}} llaves
                </button>
            </div>
        </div>
    </div>
    <div class="col col-9">
        <hr class="line">
    </div>
</div>

<ng-template #elseBlock>
    <div class="row">
        <div class="col col-6 col-md-4" 
             *ngFor="let achievement of achievements" 
             (click)="modalAchievement(achievement.id)">
            <div class="text-center achievement">
                <img class="image" [src]="achievement.image" alt="imagen-logro">
                <div class="card-body">
                    <p>{{achievement.name}}</p>
                </div>
            </div>
        </div>
        <div class="col col-9">
            <hr class="line">
        </div>
    </div>
</ng-template>
