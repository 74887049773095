<section id="maintenance-container">
  <img src="../../../../assets/imgs/Sitio_contruccion_web.png" 
       alt="Imagen de mantenimiento" 
       class="desktop"
       (click)="redirectToNewPage()" 
       title="Ir a la nueva página">
  <img src="../../../../assets/imgs/Sitio_contruccion_mobil.png" 
       alt="Imagen de mantenimiento" 
       class="mobile"
       (click)="redirectToNewPage()" 
       title="Ir a la nueva página">
</section>