import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'
import { CountryModel } from '../../models/country/country.model'
import { ReviewLessonModel } from '../../models/review-lesson/reviewLesson.model'
import { Campus, GeographicAreasByIdModel, GeographicAreasModel, Group, QuestionsBussinesModel, Subject, Teacher, ZipCodeModel } from 'src/app/models/profile/profile.model'
import { AllyCustomer } from 'src/app/models/ally/ally.model'
import { map } from 'rxjs/operators'
import { City } from 'src/app/models/city/city.model'

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private readonly url: string

  constructor (
    private readonly http: HttpClient
  ) {
    this.url = environment.url
  }

  /**
   * Metodo que consulta los paises basicos disponibles en BACKEND
   */
  getCountries (): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>(`${this.url}/api/catalogs/countries/`)
  }

  /**
   * Metodo que consulta todos los paises disponibles en BACKEND
   */
  getAllCountries (): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>(`${this.url}/api/catalogs/countries-all/`)
  }

  getCities (idState: number): Observable<City[]> {
    const url = `${this.url}/api/city-by-geographic-area/${idState}/`
    return this.http.get<City[]>(url).pipe(
      map((res: any) => res.cities)
    )
  }

  /**
   * Metodo que consulta todos los niveles de madurez disponibles en BACKEND
   */
  getMaturityLevels () {
    const url: string = `${this.url}/api/level_maturity`
    return this.http.get(url)
  }

  /**
   * Metodo que consulta todos los reviews de una lección
  */
  getReviewLesson (): Observable<ReviewLessonModel[]> {
    return this.http.get<ReviewLessonModel[]>(`${this.url}/api/catalogs/reviews/`)
  }

  /**
   * Metodo que regresa el catalogo de todos los tipos de negocio
  */
  getBusinessType (): Observable<QuestionsBussinesModel[]> {
    return this.http.get<QuestionsBussinesModel[]>(`${this.url}/admin/catalogs/business_types/`)
  }

  /**
   * Metodo que regresa el catalogo de número de empleados
  */
  getEmployeeNumber (): Observable<QuestionsBussinesModel[]> {
    return this.http.get<QuestionsBussinesModel[]>(`${this.url}/admin/catalogs/employee_numbers/`)
  }

  /**
   * Metodo que regresa el catalogo de ingresos mensuales
  */
  getWeeklyIncomes (): Observable<QuestionsBussinesModel[]> {
    return this.http.get<QuestionsBussinesModel[]>(`${this.url}/api/catalogs/weekly_incomes/`)
  }

  /**
   * Metodo que regresa el catalogo de los tipos de mejora de un negocio
  */
  getBusinessImprovements (): Observable<QuestionsBussinesModel[]> {
    return this.http.get<QuestionsBussinesModel[]>(`${this.url}/admin/catalogs/business_improvements/`)
  }

  /**
   * Metodo que regresa el catalogo de los niveles educativos
  */
  getEducationLevels (): Observable<QuestionsBussinesModel[]> {
    return this.http.get<QuestionsBussinesModel[]>(`${this.url}/admin/catalogs/education_levels/`)
  }

  /**
   * Metodo que regresa el catalogo de los profesores
  */
  getTeachers (): Observable<Teacher[]> {
    return this.http.get<Teacher[]>(`${this.url}/api/teacher/`)
  }

  /**
   * Metodo que regresa el catalogo de los campus
  */
  getCampus (): Observable<Campus[]> {
    return this.http.get<Campus[]>(`${this.url}/api/campus/`)
  }

  /**
   * Metodo que regresa el catalogo de los grupos
  */
  getGroups (): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.url}/api/group/`)
  }

  /**
   * Metodo que regresa el catalogo de las materias
  */
  getSubjects (): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${this.url}/api/subject/`)
  }

  /**
   * Metodo que regresa el catalogo de las areas geograficas
  */
  getGeographicAreas (): Observable<GeographicAreasModel[]> {
    return this.http.get<GeographicAreasModel[]>(`${this.url}/api/catalogs/geographic_areas/`)
  }

  /**
  * Metodo que regresa el catalogo de las areas geograficas
 */
  getGeographicAreasById (countryId: number): Observable<GeographicAreasByIdModel> {
    const url: string = `${this.url}/api/catalogs/geographic-area-by-country/${countryId}/`
    return this.http.get<GeographicAreasByIdModel>(url)
  }

  /**
   * Metodo que regresa si el código postal es valido
  */
  getZipCode (zipcode): Observable<ZipCodeModel> {
    return this.http.get<ZipCodeModel>(`${this.url}/api/catalogs/postal_codes/?postal_code=${zipcode}`)
  }

  /**
  * Metodo que regresa la lista de los tipos de cliente para ANSER
 */
  getKindOfClients (): Observable<AllyCustomer[]> {
    const url = `${this.url}/api/type_ally/`
    return this.http.get<AllyCustomer[]>(url)
  }
}
