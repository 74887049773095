import { Injectable } from '@angular/core'
import Swal, { SweetAlertResult } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  async basicSweet (title: string, message: string, icon: any): Promise<SweetAlertResult<unknown>> {
    return await Swal.fire({
      title: `${title}`,
      icon,
      text: `${message}`,
      showConfirmButton: false,
      heightAuto: false,
      allowOutsideClick: false,
      timer: 4000
    })
  }

  async basicSweetNotClose (title: string, message: string, icon: any): Promise<SweetAlertResult<unknown>> {
    return await Swal.fire({
      title: `${title}`,
      icon,
      text: `${message}`,
      showConfirmButton: true,
      heightAuto: false,
      allowOutsideClick: true
    })
  }

  async askSweetAlertAcelera (title: string, text: string, icon: any): Promise<SweetAlertResult<unknown>> {
    return await Swal.fire({
      title: `${title}`,
      text: `${text}`,
      icon,
      showCancelButton: true,
      confirmButtonColor: '#215aa8',
      cancelButtonColor: '#215aa8',
      cancelButtonText: 'Modificar dirección',
      confirmButtonText: 'Continuar'
    })
  }

  async askSweetWaitWebinar (): Promise<SweetAlertResult<unknown>> {
    return await Swal.fire({
      title: 'Ey!',
      text: 'Estás a punto de salir de la clase. ¡Recuerda que debes pasar más de 30 minutos para que se registre tu asistencia!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#215aa8',
      cancelButtonColor: '#215aa8',
      cancelButtonText: 'Volver a la sesión',
      confirmButtonText: 'Salir',
      allowOutsideClick: false
    })
  }
}
