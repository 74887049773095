import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { VISA_ALLIES, coppelCompanies } from 'src/app/constants/clients'

@Component({
  selector: 'app-notice-privacy',
  templateUrl: './notice-privacy.component.html',
  styleUrls: ['./notice-privacy.component.scss']
})
export class NoticePrivacyComponent implements OnInit {
  public isCoppel: boolean = false
  public isVISA: boolean = false

  constructor (private readonly router: Router) { }

  ngOnInit (): void {
    const url = this.router.url
    this.isCoppel = coppelCompanies
      .some(company => url.includes(company))
    this.isVISA = VISA_ALLIES.some(company => url.includes(company))
  }
}
