import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { coppelCompanies } from 'src/app/constants/clients'

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  public isCoppel = false

  constructor (private readonly router: Router) { }

  ngOnInit (): void {
    const url = this.router.url
    this.isCoppel = coppelCompanies
      .some(company => url.includes(company))
  }
}
