import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LessonFavoriteModel, LessonModel, LessonRecommendationModel } from '../../../models/lesson/lesson.model'
import { ActivatedRoute, Router } from '@angular/router'
import { LessonService } from '../../../services'
import { ICON } from '../../../utils/icons'
import { faHeart, faStarHalfAlt, faStar, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { ModuleModel } from 'src/app/models/module/module.model'

@Component({
  selector: 'app-card-lesson',
  templateUrl: './card-lesson.component.html',
  styleUrls: ['./card-lesson.component.scss']
})
export class CardLessonComponent implements OnInit {
  @Input() lesson: LessonModel
  @Input() finish: boolean
  @Input() explore: boolean
  @Input() module: boolean
  @Input() unblocked: boolean = true
  @Input() moduleData: ModuleModel
  @Output() favorite = new EventEmitter<LessonFavoriteModel>()
  public icon: { fav: any, fav_solid?: any } = {
    fav: ICON.FAVORITE,
    fav_solid: faHeart
  }

  public iconStar: { fav: any, fav_solid?: any, fav_half?: any } = {
    fav: ICON.STAR,
    fav_solid: faStar,
    fav_half: faStarHalfAlt
  }

  public iconUser = faUserCircle

  constructor (
    private readonly _routerActivate: ActivatedRoute,
    private readonly _router: Router,
    private readonly _lessonService: LessonService
  ) { }

  ngOnInit (): void {
  }

  async navigateToDetail (item: LessonRecommendationModel): Promise<void> {
    if (item.introduction !== undefined && item.introduction !== null) {
      let initialPath: string = ''
      if (this.finish) {
        initialPath += '../../'
      } else if (this.explore) {
        initialPath += '../seccion/'
      } else if (this.module) {
        initialPath += '../../'
      } else {
        initialPath += '../'
      }
      const fullPath: string = `${initialPath}${item.slug}/${item.introduction}`
      await this._router.navigate([fullPath], { relativeTo: this._routerActivate })
    } else {
      const fullPath: string = `../${item.slug}/parte/${String(item.current_part)}`
      await this._router.navigate([fullPath], { relativeTo: this._routerActivate })
    }
  }

  favoriteLesson (lesson: LessonRecommendationModel): void {
    this._lessonService.lessonFavorite(lesson.slug)
      .subscribe({
        next: (res) => {
          lesson.favorite = res.is_favorite
          this.favorite.emit(res)
        }
      })
  }
}
