<nav id="bar-auth" 
     class="navbar nav__bg">
  <div class="content-navbar">
    <div class="buttons">
      <button type="button" 
              routerLinkActive="active" 
              routerLink="inicio">
        <i class="fas fa-home"></i>
        <p>Inicio</p>
      </button>
      <button type="button" 
              routerLinkActive="active" 
              routerLink="lecciones">
        <i class="fa-solid fa-person-chalkboard"></i>
        <p>Contenido</p>
      </button>
      <button type="button" 
              routerLinkActive="active" 
              routerLink="logros">
        <i class="fa-solid fa-store"></i>
        <p>{{ shopMode ? 'Tienda' : 'Logros' }}</p>
      </button>
      <button type="button" 
              routerLinkActive="active" 
              routerLink="perfil">
        <i class="fa-solid fa-user"></i>
        <p>Perfil</p>
      </button>
      <button type="button" 
              (click)="openWhatsapp()"
              id="button-whatsapp">           
        <i class="fa-brands fa-whatsapp"></i>
        <p>Ayuda</p>
      </button>
    </div>
  </div>

</nav>