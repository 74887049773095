<owl-carousel-o [options]="customOptions" *ngIf="arrayLessons" id="recommend" [ngStyle]="unblocked == false ? {'opacity':'0.4'} : ''">
  <ng-container *ngFor="let lesson of arrayLessons">
    <ng-template carouselSlide>
      <app-card-lesson [lesson]="lesson" 
                       [explore]="explore" 
                       [unblocked]="unblocked" 
                       [moduleData]="moduleData"></app-card-lesson>
    </ng-template>
  </ng-container>
</owl-carousel-o>
