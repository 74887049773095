import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { URLS } from '../../../constants/urls'

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent {
  constructor (private readonly _activatedRoute: ActivatedRoute) {}

  /**
   * Redirects to a new page based on the 'brand' parameter in the current route.
   */
  public redirectToNewPage (): void {
    const ally = this._activatedRoute.snapshot.paramMap.get('brand')
    window.open(`${URLS.COPPEL_FRONT}/${ally}`, '_self')
  }
}
