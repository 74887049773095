import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ResponseWebinarAttendance, WebinarResponse } from 'src/app/models/webinar/webinar.interface'
import { environment } from 'src/environments/environment'
import { retry } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class WebinarService {
  private readonly _url: string = environment.url
  constructor (private readonly http: HttpClient) { }

  getUpComingWebinar (): Observable<WebinarResponse> {
    const url = `${this._url}/api/upcoming-webinar`
    return this.http.get<WebinarResponse>(url)
  }

  validateWebinar (id: string): Observable<Object> {
    const url = `${this._url}/api/authorized-webinar/?webinar_id=${id}`
    return this.http.get(url)
  }

  getWebinarById (id: string): Observable<Object> {
    const url = `${this._url}/api/webinar-detail/${id}`
    return this.http.get(url)
  }

  saveTimeAttendance (data: Record<string, unknown>): Observable<ResponseWebinarAttendance> {
    const url = `${this._url}/api/manage-webinar-progress/`
    return this.http.put < ResponseWebinarAttendance>(url, data).pipe(
      retry(3)
    )
  }
}
